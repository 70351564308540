import { GetUsersRequestDto } from 'apps/admin';
import { GenericResponse, PaginationResult, paymentClient, PaymentSourceEnum } from '../../../core';
import { LoginResponseDto, ResetPasswordRequest, UserInfo } from '../models';

export default {
  URL_LOGIN: '/auth/login',
  URL_REGISTER: '/auth/register',
  URL_UPDATE: '/auth/updateUser',
  URL_EMAIL_RESET_PASSWORD: '/auth/sendEmailResetPassword',
  URL_RESET_PASSWORD: '/auth/resetPassword',
  URL_GET_USER_INFO: '/auth/getUserInfo',
  URL_GET_USER_INFO_BY_ID: '/auth/getUserInfo',
  URL_GET_USERS: '/auth/getUsers',
  URL_DELETE_USER: '/auth/deleteUser',
  URL_GET_USER_PAYMENT_PROVIDERS: '/auth/getPaymentProviders',

  async login({ email, password }: { email: string; password: string }): Promise<GenericResponse<LoginResponseDto>> {
    const response = await paymentClient.post(this.URL_LOGIN, {
      email,
      password,
    });
    return response.data;
  },

  async register(formData: FormData): Promise<GenericResponse<UserInfo>> {
    try {
      const response = await paymentClient.post(this.URL_REGISTER, formData);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async sendEmailResetPassword(email: string): Promise<GenericResponse<string>> {
    try {
      const response = await paymentClient.post(this.URL_EMAIL_RESET_PASSWORD, { email });
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<string>;
    }
  },

  async resetPassword(request: ResetPasswordRequest): Promise<GenericResponse<UserInfo>> {
    try {
      const response = await paymentClient.post(this.URL_RESET_PASSWORD, request);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<UserInfo>;
    }
  },

  async getUserInfo(): Promise<GenericResponse<UserInfo>> {
    try {
      const response = await paymentClient.post(this.URL_GET_USER_INFO);
      return { isSuccess: true, result: response.data } as GenericResponse<UserInfo>;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<UserInfo>;
    }
  },

  async getUserPaymentProviders(): Promise<GenericResponse<PaymentSourceEnum[]>> {
    try {
      const response = await paymentClient.get(this.URL_GET_USER_PAYMENT_PROVIDERS);
      return { isSuccess: true, result: response.data.result } as GenericResponse<PaymentSourceEnum[]>;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<PaymentSourceEnum[]>;
    }
  },

  async getUserInfoById(userId: string): Promise<GenericResponse<UserInfo>> {
    try {
      const response = await paymentClient.post(`${this.URL_GET_USER_INFO_BY_ID}/${userId}`);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<UserInfo>;
    }
  },

  async getUsers(requestDto?: GetUsersRequestDto): Promise<GenericResponse<PaginationResult<UserInfo[]>>> {
    try {
      const response = await paymentClient.post(this.URL_GET_USERS, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async deleteUser(id: string): Promise<GenericResponse<any>> {
    try {
      const response = await paymentClient.post(this.URL_DELETE_USER, { userId: id });
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async updateUser(formData: FormData): Promise<GenericResponse<UserInfo>> {
    try {
      const response = await paymentClient.post(this.URL_UPDATE, formData);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },
};
