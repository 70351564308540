import { Home } from '@material-ui/icons';
import React from 'react';

const homeSidebarMenuList = {
  id: 'homeMenus',
  title: '',
  child: [
    {
      id: 'home',
      route: '/',
      icon: <Home />,
      label: 'Ana Sayfa',
    },
  ],
};

export default homeSidebarMenuList;
