import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { CollapseProps, Collapse } from '@material-ui/core';
import React from 'react';

export interface CollapsableAlertProps extends AlertProps {
  collapseProps?: CollapseProps;
  show: boolean;
}

const CollapsableAlert: React.FC<CollapsableAlertProps> = ({ show, collapseProps, children, ...alertProps }) => {
  return (
    <Collapse in={show} {...collapseProps}>
      <Alert {...alertProps}>{children}</Alert>
    </Collapse>
  );
};

export default CollapsableAlert;
