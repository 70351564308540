import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { Api, ConfirmDialog, DateRangeSelectRow, formatTRYSetting, InfoCard, showToast } from 'core';
import { ExitToApp, MonetizationOnOutlined } from '@material-ui/icons';
import { HideableLoadingSpinnerBox } from 'reactmuicommon';
import moment from 'moment';
import { useHistory } from 'react-router';
import { logout } from 'apps/auth/store';
import { HomeStatistics } from '../models';

const HomePage: React.FC = () => {
  const [statistics, setStatistics] = React.useState<HomeStatistics>();
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const date = new Date();
  const [showLogoutDialog, setShowLogoutDialog] = React.useState(false);

  const { userInfo } = useSelector((state: any) => ({
    userInfo: state.auth.userInfo,
  }));
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    key: 'selection',
  });

  const dispatch = useDispatch();

  const getHomeStatistics = async () => {
    setShowLoading(true);
    const response = await Api.Home.getHomeStatistics(
      moment(selectionRange.startDate).local().format('YYYY-MM-DD'),
      moment(selectionRange.endDate).local().format('YYYY-MM-DD'),
    );
    if (response.isSuccess) {
      setStatistics(response.result);
    } else {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage}`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
  };

  React.useEffect(() => {
    getHomeStatistics();
  }, [selectionRange]);

  if (showLoading) return <HideableLoadingSpinnerBox />;
  const ButtonGroupIsWeb = () => (
    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {userInfo.isAdmin && (
        <ButtonGroup
          orientation={isMobile ? 'vertical' : 'horizontal'}
          variant="contained"
          aria-label="Admin button group"
        >
          <Button
            color="primary"
            style={{ fontSize: '24px', width: '100%' }}
            onClick={() => history.push('/admin/payment/list')}
          >
            Ödeme Listesi
          </Button>
          <Button
            color="primary"
            style={{ fontSize: '24px', width: '100%' }}
            onClick={() => history.push('/admin/payment/waitingRefundApproval/list')}
          >
            İptal/İade Onay Bekleyenler
          </Button>
          <Button
            color="primary"
            style={{ fontSize: '24px', width: '100%' }}
            onClick={() => history.push('/admin/user/register')}
          >
            Kullanıcı Oluştur
          </Button>
          <Button
            color="primary"
            style={{ fontSize: '24px', width: '100%' }}
            onClick={() => history.push('/admin/users')}
          >
            Kullanıcı Listesi
          </Button>
        </ButtonGroup>
      )}

      <ButtonGroup
        orientation={isMobile ? 'vertical' : 'horizontal'}
        variant="contained"
        aria-label="Admin button group"
      >
        {userInfo.isAccounting && (
          <Button
            color="primary"
            style={{ fontSize: '24px', width: '100%' }}
            onClick={() => history.push('/admin/payment/list')}
          >
            Ödeme Listesi
          </Button>
        )}
        <Button
          color="primary"
          style={{ fontSize: '24px', width: '100%', borderRadius: 0 }}
          onClick={() => history.push('/paynet/payment/')}
        >
          Ödeme Al
        </Button>
        <Button
          color="primary"
          style={{ fontSize: '24px', width: '100%' }}
          onClick={() => history.push('/paynet/payment/list/')}
        >
          Ödemelerim Listesi
        </Button>
      </ButtonGroup>
    </Grid>
  );

  const ButtonGroupIsMobile = () => (
    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ButtonGroup
        orientation={isMobile ? 'vertical' : 'horizontal'}
        variant="contained"
        aria-label="Admin button group"
      >
        {userInfo.isAdmin && (
          <ButtonGroup
            orientation={isMobile ? 'vertical' : 'horizontal'}
            variant="contained"
            aria-label="Admin button group"
          >
            <Button
              color="primary"
              style={{ fontSize: '24px', width: '100%' }}
              onClick={() => history.push('/admin/payment/list')}
            >
              Ödeme Listesi
            </Button>
            <Button
              color="primary"
              style={{ fontSize: '24px', width: '100%' }}
              onClick={() => history.push('/admin/payment/waitingRefundApproval/list')}
            >
              İptal/İade Onay Bekleyenler
            </Button>
            <Button
              color="primary"
              style={{ fontSize: '24px', width: '100%' }}
              onClick={() => history.push('/admin/user/register')}
            >
              Kullanıcı Oluştur
            </Button>
            <Button
              color="primary"
              style={{ fontSize: '24px', width: '100%' }}
              onClick={() => history.push('/admin/users')}
            >
              Kullanıcı Listesi
            </Button>
          </ButtonGroup>
        )}

        <Button
          color="primary"
          style={{ fontSize: '24px', width: '100%' }}
          onClick={() => history.push('/paynet/payment/')}
        >
          Ödeme Al
        </Button>
        <Button
          color="primary"
          style={{ fontSize: '24px', width: '100%' }}
          onClick={() => history.push('/paynet/payment/list/')}
        >
          Ödemelerim Listesi
        </Button>
      </ButtonGroup>
    </Grid>
  );

  return (
    <React.Fragment>
      <ConfirmDialog
        title="Çıkış"
        open={showLogoutDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={() => dispatch(logout())}
        handleClose={() => setShowLogoutDialog(false)}
      >
        Çıkış yapmak istiyor musunuz ?
      </ConfirmDialog>
      <IconButton
        color="primary"
        style={{
          float: 'right',
        }}
        onClick={() => setShowLogoutDialog(true)}
      >
        <ExitToApp />
      </IconButton>

      <Grid container spacing={5} justify="center">
        {isMobile ? <ButtonGroupIsMobile /> : <ButtonGroupIsWeb />}
        <Grid item xs={12}>
          <DateRangeSelectRow selectionRange={selectionRange} onSelectionRange={setSelectionRange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCard
            icon={<MonetizationOnOutlined />}
            title="Toplam Alınan Brüt Ödeme"
            text={formatTRYSetting.format(statistics?.totalGrossPaymentAmount ?? 0)}
            backgroundColor="#03a65a"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCard
            icon={<MonetizationOnOutlined />}
            title="Onay Bekleyen İşlem Tutarı"
            text={formatTRYSetting.format(statistics?.totalWaitingPaymentAmount ?? 0)}
            backgroundColor="#f39c12"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCard
            icon={<MonetizationOnOutlined />}
            title="Toplam İptal/İade İşlem Tutarı"
            text={formatTRYSetting.format(statistics?.totalCancelledPaymentAmount ?? 0)}
            backgroundColor="#c64333"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCard
            icon={<MonetizationOnOutlined />}
            title="Toplam Net Ödeme Tutarı"
            text={formatTRYSetting.format(statistics?.totalNetAmount ?? 0)}
            backgroundColor="#03a65a"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default HomePage;
