import { Money } from '@material-ui/icons';
import React from 'react';

const adminSidebarMenuList = {
  id: 'adminMenus',
  title: '',
  isForAdmin: true,
  isForAccounting: true,
  child: [
    {
      id: 'adminSide',
      label: 'Yönetici İşlemleri',
      icon: <Money />,
      child: [
        {
          id: 'adminPaymentList',
          label: 'Ödeme Listesi',
          icon: <Money />,
          route: '/admin/payment/list',
        },
        {
          id: 'adminWaitingRefundApprovalList',
          label: 'İptal/İade Onay Bekleyenler',
          icon: <Money />,
          route: '/admin/payment/waitingRefundApproval/list',
          isForAdmin: true,
        },
        {
          id: 'registerUser',
          label: 'Kullanıcı Oluştur',
          icon: <Money />,
          route: '/admin/user/register',
          isForAdmin: true,
        },
        {
          id: 'userList',
          label: 'Kullanıcı Listesi',
          icon: <Money />,
          route: '/admin/users',
          isForAdmin: true,
        },
      ],
    },
  ],
};

export default adminSidebarMenuList;
