export enum PaymentTransactionStatusEnum {
  PRE_AUTH_OR_WAITING_PAYMENT = 0, // Provizyon ödeme alındıgında veya ilk paylink olusturuldugunda bu statüye geçer.
  COMPLETED = 1,
  TIMEOUT = 2,
  FAILED = 3,
  EMPTY = 4,
  CANCELLED_OR_RETURNED = 5, // ödemenin tamamı iptal/iade oldugunda
  WAITING_APPROVAL_CANCEL_OR_RETURN = 6, // ödemenin tamamı iade/iptal talebi alındıgında bu statüye geçer, ardından onaylandıktan CANCELLED_OR_RETURNED olur
  REJECTED_CANCEL_OR_RETURN = 7, // ödemenin tamamının iade/iptal talebi olumsuz sonuclandıgında bu statüye geçer.
  PARTIAL_RETURNED_OR_CANCELED = 8, // en az 1 tane parçalı iade yapılmışsa bu statüye geçer, parça parça tüm ödeme iade edilmişse, CANCELLED_OR_RETURNED statüsüne geçer
  WAITING_TO_SEND_ACCOUNTING_CONTROL = 20, // Muhasebe kontrolü için yapılmış statü. NKOLAY'da ödeme tamamlanınca buna geçer
  ACCOUNTING_CONTROL_WAITING = 30, // nkolay icinAdmin belgeleri kontrol ettikten sonra bu statuye geçer ve muhasebe kontrol eder.
}
