/* eslint-disable max-len */
import { AuthApi } from 'apps/auth';
import { HomeApi } from 'apps/home';
import { PaynetApi } from 'apps/paynet';
import {
  GenericResponse,
  GetPaymentTransactionRefundsRequestDto,
  GetPaymentTransactionsRequestDto,
  PaginationResult,
  paymentClient,
  PaymentTransaction,
  PaymentTransactionFile,
  PaymentTransactionRefund,
  PaymentTransactionSlip,
  SendConsentFileRequestDto,
} from 'core';

export default {
  Auth: AuthApi,
  Paynet: PaynetApi,
  Home: HomeApi,
  GET_TRANSACTIONS: '/payment/getTransactions',
  GET_TRANSACTIONS_WITHOUT_PAGINATION: '/payment/getTransactionsWithoutPagination',
  GET_TRANSACTION_REFUNDS: '/payment/transactionRefunds',
  UPLOAD_FILES: '/payment/uploadFiles',
  URL_GET_TRANSACTION_SLIP: (paymentTransactionId: number) => `/payment/getTransactionSlip/${paymentTransactionId}`,
  URL_SEND_CONSENT_FILE_LINK: '/payment/sendConsentFileLink',
  URL_APPROVE_CONSENT_FILE: (paymentTransactionId: number, approveCode: string) =>
    `/payment/approveConsentFile/${paymentTransactionId}/${approveCode}`,
  URL_HAS_WAITING_CONSENT_APPROVAL: (paymentTransactionId: number) =>
    `/payment/hasWaitingConsentApproval/${paymentTransactionId}`,
  URL_GET_CONSENT_FILE_LINK: (fileUUID: string) => `/payment/getConsentFileLink/${fileUUID}`,

  GSM_QUERIES_FINDEKS: (id: any) => `/payment/findeksGsmQueries/${id}`,
  GET_PAYMENT_DETAIL_AS_PDF: (paymentTransactionId: number) =>
    `/payment/getPaymentDetailAsFile/${paymentTransactionId}`,

  setBearerToken(token: string): void {
    paymentClient.defaults.headers.Authorization = `Bearer ${token}`;
  },

  async addInterceptor(callback: any, callbackError: any) {
    paymentClient.interceptors.response.use(callback, callbackError);
  },

  async getPaymentTransactions(
    requestDto?: GetPaymentTransactionsRequestDto,
  ): Promise<GenericResponse<PaginationResult<PaymentTransaction[]>>> {
    try {
      const response = await paymentClient.post(this.GET_TRANSACTIONS, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getTransactionsWithoutPagination(
    requestDto?: GetPaymentTransactionsRequestDto,
  ): Promise<GenericResponse<PaginationResult<PaymentTransaction[]>>> {
    try {
      const response = await paymentClient.post(this.GET_TRANSACTIONS_WITHOUT_PAGINATION, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getPaymentTransactionRefunds(
    requestDto?: GetPaymentTransactionRefundsRequestDto,
  ): Promise<GenericResponse<PaginationResult<PaymentTransactionRefund[]>>> {
    try {
      const response = await paymentClient.post(this.GET_TRANSACTION_REFUNDS, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async uploadFiles(formData: FormData): Promise<GenericResponse<PaymentTransactionFile[] | PaymentTransactionFile>> {
    try {
      const response = await paymentClient.post(this.UPLOAD_FILES, formData);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getPaymentTransactionSlip(paymentTransactionId: number): Promise<GenericResponse<PaymentTransactionSlip>> {
    try {
      const response = await paymentClient.get(this.URL_GET_TRANSACTION_SLIP(paymentTransactionId));
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async sendConsentFileLink(requestDto: SendConsentFileRequestDto): Promise<GenericResponse<any>> {
    try {
      const response = await paymentClient.post(this.URL_SEND_CONSENT_FILE_LINK, requestDto);
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async approveConsentFile(paymentTransactionId: number, approveCode: string): Promise<GenericResponse<any>> {
    try {
      const response = await paymentClient.post(this.URL_APPROVE_CONSENT_FILE(paymentTransactionId, approveCode));
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async hasWaitingConsentApproval(paymentTransactionId: number): Promise<GenericResponse<boolean>> {
    try {
      const response = await paymentClient.get(this.URL_HAS_WAITING_CONSENT_APPROVAL(paymentTransactionId));
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getConsentFileLink(fileUUID: string): Promise<GenericResponse<string>> {
    try {
      const response = await paymentClient.get(this.URL_GET_CONSENT_FILE_LINK(fileUUID));
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      } as GenericResponse<any>;
    }
  },

  async getGsmQueriesFindeks(govId: number) {
    try {
      const response = await paymentClient.get(this.GSM_QUERIES_FINDEKS(govId));
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      };
    }
  },

  async getPaymentDetailAsPdf(paymentTransactionId: number) {
    try {
      const response = await paymentClient.get(this.GET_PAYMENT_DETAIL_AS_PDF(paymentTransactionId), {
        responseType: 'blob',
      });
      return response.data;
    } catch (e) {
      return {
        isSuccess: false,
        errorMessage: e.response?.data?.message ?? e.message,
      };
    }
  },
};
