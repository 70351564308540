import React from 'react';
import {
  AppBar,
  Badge,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  AccountCircle,
  Menu as MenuIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  ExitToApp as ExitToAppIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';
import { Api, MenuBlock, MenuBlockInfo, showToast, toggleDrawer, toggleMobileDrawer } from 'core';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import clsx from 'classnames';
import { useHistory } from 'react-router-dom';
import { logout } from 'apps/auth/store';
import { homeSidebarMenuList } from 'apps/home';
import { adminSidebarMenuList } from 'apps/admin';
import { paynetSidebarMenuList } from 'apps/paynet';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 10%, ${theme.palette.primary.light} 92%)`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  supportIcon: {
    color: 'white',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  snackBar: {
    [theme.breakpoints.down('xs')]: {
      bottom: 90,
    },
    marginLeft: '20px',
  },
  image: {
    width: '100px',
    height: '90px',
    marginRight: '20px',
    marginBottom: '5px',
    marginTop: '5px',
  },
  imageDiv: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'center',
    alignItems: 'center',
  },
  downIcon: {
    color: theme.palette.common.white,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  upIcon: {
    color: theme.palette.common.white,
    userSelect: 'none',
    pointerEvents: 'none',
    transform: 'rotate(180deg)',
  },
}));

function Layout({ children }: { children: any }) {
  const menuBlockInfos: MenuBlockInfo[] = [homeSidebarMenuList, adminSidebarMenuList, paynetSidebarMenuList];

  const classes = useStyles();
  const theme = useTheme();
  const smDownMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const { userInfo, isDrawerOpen, isMobileDrawerOpen, paymentTransactionIdForIframe } = useSelector(
    (state: any) => ({
      userInfo: state.auth.userInfo,
      isDrawerOpen: state.core.isDrawerOpen,
      isMobileDrawerOpen: state.core.isMobileDrawerOpen,
      paymentTransactionIdForIframe: state.core.paymentTransactionId,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  React.useEffect(() => {
    Api.addInterceptor(
      (response: any) => {
        return response;
      },
      (error: any) => {
        const { status } = error.response;
        if (status === 401) {
          dispatch(
            showToast({
              message: `Tekrar giriş yapmanız gerekmektedir`,
              severity: 'warning',
            }),
          );
          dispatch(logout());
        }
        return Promise.reject(error);
      },
    );
  }, []);

  // kullanıcı iframe ile geldiğinde token localstorage'a kaydediliyor.
  // Başka sekmede açıp veya sayfayı yenilediğinde güvenlik açığı olmaması için tekrar tokenla gelmesi gerekiyor.
  // bu yüzden eğer iframe ile gelmediyse yani paymentTransactionId yoksa ve daha once iframe ile geldiyse, logout et.
  React.useEffect(() => {
    if (!paymentTransactionIdForIframe && localStorage.getItem('isOpenIframe') === 'true') {
      localStorage.removeItem('isOpenIframe');
      dispatch(logout());
    }
  }, [paymentTransactionIdForIframe]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    if (smDownMatches) {
      dispatch(toggleMobileDrawer(true));
    } else {
      dispatch(toggleDrawer(true));
    }
  };

  const handleDrawerClose = () => {
    if (smDownMatches) {
      dispatch(toggleMobileDrawer(false));
    } else {
      dispatch(toggleDrawer(false));
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logoutClicked = () => {
    dispatch(logout());
    handleMenuClose();
  };

  const goProfile = () => {
    history.push('/my/profile');
    handleMenuClose();
  };

  const drawerContent = (
    <React.Fragment>
      <div className={classes.drawerHeader}>
        <img src="/occopay_logo.png" className={classes.image} />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>

      <Divider />

      {menuBlockInfos.map((menuBlockInfo, index) => {
        if (menuBlockInfo.isForAdmin || menuBlockInfo.isForAccounting) {
          if (!userInfo.isAdmin || !userInfo.isAccounting) {
            return null;
          }
        }
        return (
          <React.Fragment key={menuBlockInfo.id}>
            <MenuBlock menuBlockInfo={menuBlockInfo} />
            {index === menuBlockInfos.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={goProfile}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <AccountCircle />
          </Badge>
        </IconButton>
        Hesabım
      </MenuItem>
      <MenuItem onClick={logoutClicked}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <ExitToAppIcon />
          </Badge>
        </IconButton>
        Çıkış Yap
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {userInfo.isAdmin && (
        <AppBar
          hidden={paymentTransactionIdForIframe != undefined}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: !smDownMatches && isDrawerOpen,
          })}
        >
          <Toolbar>
            <div className="d-flex align-items-center w-100">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, !smDownMatches && isDrawerOpen && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Hidden xsDown implementation="css">
                <Typography variant="h6" className="flex-grow-1" noWrap>
                  {process.env.REACT_APP_NAME}
                </Typography>
              </Hidden>

              <Box flexGrow="1" />
              <Hidden xsDown implementation="css">
                <div>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Button
                      startIcon={<AccountCircle />}
                      onClick={handleProfileMenuOpen}
                      aria-controls={menuId}
                      color="inherit"
                    >
                      {userInfo.agentName}
                      <ExpandMoreIcon className={anchorEl ? classes.upIcon : classes.downIcon} />
                    </Button>
                  </Box>
                </div>
              </Hidden>
              <Hidden smUp implementation="css">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <IconButton onClick={handleProfileMenuOpen} aria-controls={menuId} color="inherit">
                    <AccountCircle />
                  </IconButton>
                </Box>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      )}

      {renderMenu}
      {renderMobileMenu}

      {/*  <nav className={classes.drawer}>
        {!paymentTransactionIdForIframe && (
          <React.Fragment>
            <Hidden smUp implementation="css">
              <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="left"
                open={isMobileDrawerOpen}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawerContent}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawerContent}
              </Drawer>
            </Hidden>
          </React.Fragment>
        )}
      </nav>
 */}
      <main className={clsx(classes.content)}>{children}</main>
    </div>
  );
}

export default Layout;
