/* eslint-disable import/no-extraneous-dependencies */
import MaterialTable, { MaterialTableProps } from 'material-table';
import React from 'react';
import { tr } from 'date-fns/locale';
import XLSX from 'xlsx';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useTheme } from '@material-ui/core/styles';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

// eslint-disable-next-line @typescript-eslint/ban-types
export type TranslatedMaterialTableProps<T extends object> = MaterialTableProps<T> & { tableId?: string };
export interface GenerateLookUpTableOptions {
  key: string;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function generateLookUpTable(data: any[], options: GenerateLookUpTableOptions = { key: 'id', value: 'name' }) {
  const table: any = {};
  data.forEach((elem: any) => {
    table[elem[options.key]] = elem[options.value];
  });
  return table;
}

export const exportToExcel = (columns: any, data: any) => {
  const filteredColumns = columns.filter((c: any) => c.export ?? true);
  const columnInfo = filteredColumns.reduce(
    (acc: any, column: any) => {
      if (column.field) {
        const headerLabel = column.title || column.field;
        acc.header.push(headerLabel);
        acc.map[column.field] = headerLabel;
        acc.typeMap[column.field] = column.type;
        acc.lookupMap[column.field] = column.lookup;
        acc.fieldMap[column.field] = column.field;
      }
      return acc;
    },
    { fieldMap: {}, map: {}, header: [], typeMap: {}, lookupMap: {} },
  );

  const mappedData = data.map((row: any) =>
    Object.entries(columnInfo.fieldMap).reduce((acc: any, [key, value]) => {
      const fields = (value as string).split('.');
      const dataIn = fields.reduce((accData, next) => {
        if (accData == null) return null;
        accData = accData[next];
        return accData;
      }, row);
      const type = columnInfo.typeMap[key];
      const titleKey = columnInfo.map[key];
      if (dataIn != null) {
        switch (type) {
          case 'date':
            acc[titleKey] = moment(dataIn as string).format('DD/MM/YYYY HH:mm');
            break;
          case 'datetime':
            acc[titleKey] = moment(dataIn as string).format('DD/MM/YYYY HH:mm');
            break;
          default:
            if (columnInfo.lookupMap[key]) {
              acc[titleKey] = columnInfo.lookupMap[key][dataIn as string];
            } else {
              acc[titleKey] = dataIn as string;
            }
            break;
        }
      } else {
        acc[titleKey] = '';
      }

      return acc;
    }, {}),
  );
  const ws = XLSX.utils.json_to_sheet(mappedData, {
    header: columnInfo.header,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, `${moment().format('DD/MM/YYYY HH:mm')}.xlsx`);
};

export const exportToPdf = (columns: any, data: any) => {
  const filteredColumns = columns.filter(
    (c: any) => typeof c.field === 'string' && typeof c.title === 'string' && (c.export ?? true),
  );
  const fields = filteredColumns.map((c: any) => c.field);
  const fieldTypes = filteredColumns.map((c: any) => c.type);
  const lookup = filteredColumns.map((c: any) => c.lookup);
  const mappedData = data.map((row: any) => {
    fields.forEach((field: any, index: any) => {
      const fieldType = fieldTypes[index];
      const rowData = field.split('.').reduce((accData: any, next: any) => {
        if (accData == null) return null;
        accData = accData[next];
        return accData;
      }, row);
      switch (fieldType) {
        case 'date':
          row[field] = moment(rowData as string).format('DD/MM/YYYY HH:mm');
          break;
        case 'datetime':
          row[field] = moment(rowData as string).format('DD/MM/YYYY HH:mm');
          break;
        default:
          if (lookup[index]) {
            row[field] = lookup[index][rowData];
          } else {
            row[field] = rowData;
          }
          break;
      }
    });
    return fields.map((f: any) => {
      const data = row[f];
      if (!data) {
        return '';
      }
      return data;
    });
  });
  const docDefinition = {
    pageSize: { width: 2000, height: 'auto' },
    content: [
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            layout: {
              hLineWidth(i: any, node: any) {
                if (i === 0 || i === node.table.body.length) {
                  return 0;
                }
                return i === node.table.headerRows ? 2 : 1;
              },
              vLineWidth(i: any) {
                return 0;
              },
              hLineColor(i: any) {
                return i === 1 ? 'black' : '#aaa';
              },
              paddingLeft(i: any) {
                return i === 0 ? 0 : 8;
              },
              paddingRight(i: any, node: any) {
                return i === node.table.widths.length - 1 ? 0 : 8;
              },
              fillColor(i: any, node: any) {
                return i % 2 === 0 ? '#F8F8F8' : null;
              },
            },
            table: {
              body: [filteredColumns.map((c: any) => c.title), ...mappedData],
            },
          },
          { width: '*', text: '' },
        ],
      },
    ],
  };
  pdfMake.createPdf(docDefinition as TDocumentDefinitions).download(`${moment().format('DD/MM/YYYY HH:mm')}.pdf`);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const TranslatedMaterialTable = <T extends object>(props: TranslatedMaterialTableProps<T>) => {
  const { options, ...tableProps } = props;
  const [savedColumns, setSavedColumns] = React.useState<any[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const theme = useTheme();
  const isDarkModeActive = theme.palette.type === 'dark';
  const newOptions = options ? { ...options } : {};
  newOptions.pageSize = newOptions.pageSize || 100;
  newOptions.padding = newOptions.padding || 'default';
  newOptions.maxBodyHeight = newOptions.maxBodyHeight || '65vh';
  newOptions.emptyRowsWhenPaging = newOptions.emptyRowsWhenPaging || false;
  newOptions.pageSizeOptions = [5, 10, 15, 20];
  newOptions.headerStyle = { fontWeight: 'bolder' };
  newOptions.actionsCellStyle = {
    color: isDarkModeActive ? theme.palette.getContrastText(theme.palette.background.default) : '#000',
  };
  newOptions.rowStyle = (_data: any, index: number, _level: number) => {
    return index % 2
      ? {
          backgroundColor: isDarkModeActive ? '#424242' : '#ecf2f9',
          color: isDarkModeActive ? theme.palette.getContrastText(theme.palette.background.default) : '#565657',
        }
      : {
          backgroundColor: isDarkModeActive ? 'rgb(52 52 52)' : '#fafbfc',
          color: isDarkModeActive ? theme.palette.getContrastText(theme.palette.background.default) : '#565657',
        };
  };

  React.useEffect(() => {
    if (tableProps.tableId) {
      const newTableData = { ...tableProps.columns };
      // newTableData.columns = [];
      // eslint-disable-next-line no-restricted-syntax
      if (savedColumns && savedColumns.length > 0) {
        savedColumns.forEach((savedColumn, columnIndex) => {
          if (newTableData) {
            // @ts-ignore
            const column = Object.values(newTableData).find((e) => e.title === savedColumn);
            // const column = newTableData.find((e) => e.title === savedColumn);
            if (column) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              column.tableData.columnOrder = columnIndex;
            }
          }
        });
      }
      tableProps.columns = newTableData;
    }
  }, [savedColumns]);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const handleColumnDrag = (sourceIndex: any, destinationIndex: any) => {
    if (tableProps.tableId) {
      const newTableData = [...savedColumns];
      const sourceColumn = savedColumns[sourceIndex];
      const destinationColumn = savedColumns[destinationIndex];
      newTableData[sourceIndex] = destinationColumn;
      newTableData[destinationIndex] = sourceColumn;
      setSavedColumns(newTableData);
      localStorage.setItem(tableProps.tableId, JSON.stringify(newTableData));
      if (props.onColumnDragged) {
        props.onColumnDragged(sourceIndex, destinationIndex);
      }
    }
  };

  React.useEffect(() => {
    if (tableProps.columns && tableProps.tableId) {
      // @ts-ignore
      const columnTitleArray: any = Object.values(tableProps.columns).map((column) => {
        return column.title;
      });
      if (localStorage.getItem(tableProps.tableId) === null) {
        localStorage.setItem(tableProps.tableId, JSON.stringify(columnTitleArray));
      }
      setSavedColumns(JSON.parse(localStorage.getItem(tableProps.tableId) || '[]'));
    }
  }, []);

  return (
    <MaterialTable
      {...tableProps}
      // @ts-ignore
      onColumnDragged={handleColumnDrag}
      options={newOptions}
      localization={{
        pagination: {
          labelDisplayedRows: '{count} elemanın {from}-{to} aralığı',
          labelRowsSelect: ' satır',
        },
        toolbar: {
          nRowsSelected: '{0} satır seçildi',
          searchPlaceholder: tableProps.localization?.toolbar?.searchPlaceholder
            ? tableProps.localization?.toolbar?.searchPlaceholder
            : 'Arama',
          searchTooltip: 'Arama',
          exportTitle: 'Listeyi İndir',
          exportCSVName: 'EXCEL',
          exportPDFName: 'PDF',
        },
        header: {
          actions: 'Eylemler',
        },
        body: {
          dateTimePickerLocalization: tr,
          emptyDataSourceMessage: 'Gösterilecek kayıt yok',
          filterRow: {
            filterTooltip: 'Filtre',
          },
          addTooltip: 'Ekle',
          deleteTooltip: 'Sil',
          editTooltip: 'Düzenle',
          editRow: {
            saveTooltip: 'Kaydet',
            cancelTooltip: 'İptal',
            deleteText: 'Sil',
          },
        },
      }}
    />
  );
};

export default TranslatedMaterialTable;
